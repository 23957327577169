import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";

export const initialState = {
  tradingBots: [],
  getTradingBotsLoading: false,
  createTradingBotLoading: false,
  updateTradingBotLoading: false,
  deleteTradingBotLoading: false,
};

export const getTradingBots = createAsyncThunk(
  "trades/trading_bots",
  async (portfolioId, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `/api/trading_bots/trading_bots/${portfolioId}/`
      );
      if (response?.trading_bots) {
        return response.trading_bots;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createTradingBot = createAsyncThunk(
  "createTradingBot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/trading_bots/trading_bots/${payload?.portfolio_id}/`,
        payload
      );
      if (response?.trading_bots) {
        return response.trading_bots;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateTradingBot = createAsyncThunk(
  "updateTradingBot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.put(
        `/api/trading_bots/trading_bot/${payload?.id}/`,
        payload
      );
      if (response?.trading_bots) {
        return response.trading_bots;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteTradingBot = createAsyncThunk(
  "deleteTradingBot",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload)
      const response = await service.delete(
        `/api/trading_bots/trading_bot/${payload}/`);
      if (response) {
        console.log("Delete Bot Response", response);
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const tradingBotsSlice = createSlice({
  name: "liveTrades",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTradingBots.pending, (state) => {
        state.getTradingBotsLoading = true;
      })
      .addCase(getTradingBots.fulfilled, (state, action) => {
        state.getTradingBotsLoading = false;
        state.tradingBots = action.payload;
      })
      .addCase(getTradingBots.rejected, (state) => {
        state.getTradingBotsLoading = false;
      })
      .addCase(createTradingBot.pending, (state) => {
        state.createTradingBotLoading = true;
      })
      .addCase(createTradingBot.fulfilled, (state, action) => {
        state.createTradingBotLoading = false;
        state.tradingBots = (state.tradingBots || [])?.concat(action?.payload);
      })
      .addCase(createTradingBot.rejected, (state) => {
        state.createTradingBotLoading = false;
      })
      .addCase(updateTradingBot.pending, (state) => {
        state.updateTradingBotLoading = true;
      })
      .addCase(updateTradingBot.fulfilled, (state, action) => {
        state.updateTradingBotLoading = false;
        const updatedIndex = state.tradingBots.findIndex(
          (account) => account.id === action.payload.id
        );
        if (updatedIndex !== -1) {
          state.tradingBots[updatedIndex] = action.payload;
        }
      })
      .addCase(updateTradingBot.rejected, (state) => {
        state.updateTradingBotLoading = false;
      })
      .addCase(deleteTradingBot.pending, (state) => {
        state.deleteTradingBotLoading = true;
      })
      .addCase(deleteTradingBot.fulfilled, (state, action) => {
        state.deleteTradingBotLoading = false;
        console.log("AP", action, state.tradingBots);
        state.tradingBots = state.tradingBots.filter(
          (bot) => bot.id !== action.meta.arg
        );
      })
      .addCase(deleteTradingBot.rejected, (state) => {
        state.deleteTradingBotLoading = false;
      });
  },
});

export default tradingBotsSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";

export const initialState = {
  baseBots: [],
  getBaseBotsLoading: false,
};

export const getBaseBots = createAsyncThunk(
  "baseBots",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.get(`/api/base_strategies/base_strategies/`);
      if (response?.base_strategies) {
        return response?.base_strategies;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const baseBotsSlice = createSlice({
  name: "baseBots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBaseBots.pending, (state) => {
        state.getBaseBotsLoading = true;
      })
      .addCase(getBaseBots.fulfilled, (state, action) => {
        state.getBaseBotsLoading = false;
        state.baseBots = action.payload;
      })
      .addCase(getBaseBots.rejected, (state) => {
        state.getBaseBotsLoading = false;
      });
  },
});

export default baseBotsSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";

export const initialState = {
  brokerAccounts: [],
  getBrokerAccountsLoading: false,
  createBrokerAccountLoading: false,
  updateBrokerAccountLoading: false,
  deleteBrokerAccountLoading: false,
  loginBrokerAccountId: null,
};

export const getBrokerAccounts = createAsyncThunk(
  "brokerAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await service.get(`/api/accounts/all-accounts/`);
      if (response?.broker_accounts) {
        return response.broker_accounts;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getBrokers = createAsyncThunk(
  "createBrokerAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.get(`/api/brokers/brokers/`);
      if (response?.brokers) {
        return response.brokers;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createBrokerAccount = createAsyncThunk(
  "createBrokerAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(`/api/accounts/accounts/`, payload);
      if (response?.broker_accounts) {
        return response.broker_accounts;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateBrokerAccount = createAsyncThunk(
  "updateBrokerAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.put(
        `/api/accounts/accounts/${payload?.id}/`,
        payload
      );
      if (response?.broker_accounts) {
        return response.broker_accounts;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteBrokerAccount = createAsyncThunk(
  "deleteBrokerAccount",
  async (payload, { rejectWithValue }) => {
    try {
      await service.delete(`/api/accounts/accounts/${payload?.id}/`);
      return { id: payload?.id };
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const loginBrokerAccount = createAsyncThunk(
  "loginBrokerAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/accounts/account-login/${payload?.accountId}/`,
        {
          request_token: payload?.request_token,
          broker_name: payload?.brokerName
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || err?.message || "Error"
      );
    }
  }
);

export const brokerAccountsSlice = createSlice({
  name: "brokerAccounts",
  initialState,
  reducers: {
    setLoginBrokerAccountId: (state, action) => {
      state.loginBrokerAccountId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrokerAccounts.pending, (state) => {
        state.getBrokerAccountsLoading = true;
      })
      .addCase(getBrokerAccounts.fulfilled, (state, action) => {
        state.getBrokerAccountsLoading = false;
        state.brokerAccounts = action.payload;
      })
      .addCase(getBrokerAccounts.rejected, (state) => {
        state.getBrokerAccountsLoading = false;
      })
      .addCase(createBrokerAccount.pending, (state) => {
        state.createBrokerAccountLoading = true;
      })
      .addCase(createBrokerAccount.fulfilled, (state, action) => {
        state.createBrokerAccountLoading = false;
        state.brokerAccounts = (state.brokerAccounts || [])?.concat(
          action?.payload
        );
      })
      .addCase(createBrokerAccount.rejected, (state) => {
        state.createBrokerAccountLoading = false;
      })
      .addCase(updateBrokerAccount.pending, (state) => {
        state.updateBrokerAccountLoading = true;
      })
      .addCase(updateBrokerAccount.fulfilled, (state, action) => {
        state.updateBrokerAccountLoading = false;
        const updatedIndex = state.brokerAccounts.findIndex(
          (account) => account.id === action.payload.id
        );
        if (updatedIndex !== -1) {
          state.brokerAccounts[updatedIndex] = action.payload;
        }
      })
      .addCase(updateBrokerAccount.rejected, (state) => {
        state.updateBrokerAccountLoading = false;
      })
      .addCase(deleteBrokerAccount.pending, (state) => {
        state.deleteBrokerAccountLoading = true;
      })
      .addCase(deleteBrokerAccount.fulfilled, (state, action) => {
        state.deleteBrokerAccountLoading = false;
        state.brokerAccounts = state.brokerAccounts?.filter(
          (account) => account.id !== action.payload.id
        );
      })
      .addCase(deleteBrokerAccount.rejected, (state) => {
        state.deleteBrokerAccountLoading = false;
      })
      .addCase(loginBrokerAccount.pending, (state) => {
        state.getBrokerAccountsLoading = true;
      })
      .addCase(loginBrokerAccount.fulfilled, (state) => {
        state.getBrokerAccountsLoading = false;
        state.loginBrokerAccountId = null;
      })
      .addCase(loginBrokerAccount.rejected, (state) => {
        state.getBrokerAccountsLoading = false;
      });
  },
});

export const { setLoginBrokerAccountId } = brokerAccountsSlice.actions;

export default brokerAccountsSlice.reducer;

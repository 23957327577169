import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";
import axios from "axios";
import { SUPABASE_CLIENT_ANON_KEY } from "configs/AppConfig";

export const initialState = {
  dailyBots: [],
  getDailyBotsLoading: false,
  createDailyBotLoading: false,
  updateDailyBotLoading: false,
  deleteDailyBotLoading: false,
};

export const getDailyBots = createAsyncThunk(
  "livetrades/daily_bots",
  async (portfolioId, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `/api/daily_bots/daily_bots/${portfolioId}/`
      );
      console.log(response);
      if (response?.daily_bots) {
        return response.daily_bots;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createDailyBot = createAsyncThunk(
  "createDailyBot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/daily_bots/daily_bots/${payload?.portfolio_id}/`,
        payload
      );
      if (response?.daily_bots) {
        return response.daily_bots;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateDailyBot = createAsyncThunk(
  "updateDailyBot",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.put(
        `/api/daily_bots/daily_bot/${payload?.id}/`,
        payload
      );
      if (response?.daily_bots) {
        return response.daily_bots;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteDailyBot = createAsyncThunk(
  "deleteDailyBot",
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload)
      const response = await service.delete(
        `/api/daily_bots/daily_bot/${payload}/`);
      if (response) {
        console.log("Delete Bot Response", response);
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const dailyBotsSlice = createSlice({
  name: "DailyBots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDailyBots.pending, (state) => {
        state.getDailyBotsLoading = true;
      })
      .addCase(getDailyBots.fulfilled, (state, action) => {
        state.getDailyBotsLoading = false;
        state.dailyBots = action.payload;
      })
      .addCase(getDailyBots.rejected, (state) => {
        state.getDailyBotsLoading = false;
      })
      .addCase(createDailyBot.pending, (state) => {
        state.createDailyBotLoading = true;
      })
      .addCase(createDailyBot.fulfilled, (state, action) => {
        state.createDailyBotLoading = false;
        state.dailyBots = (state.dailyBots || [])?.concat(action?.payload);
      })
      .addCase(createDailyBot.rejected, (state) => {
        state.createDailyBotLoading = false;
      })
      .addCase(updateDailyBot.pending, (state) => {
        state.updateDailyBotLoading = true;
      })
      .addCase(updateDailyBot.fulfilled, (state, action) => {
        state.updateDailyBotLoading = false;
        const updatedIndex = state.dailyBots.findIndex(
          (account) => account.id === action.payload.id
        );
        if (updatedIndex !== -1) {
          state.dailyBots[updatedIndex] = action.payload;
        }
      })
      .addCase(updateDailyBot.rejected, (state) => {
        state.updateDailyBotLoading = false;
      })
      .addCase(deleteDailyBot.pending, (state) => {
        state.deleteDailyBotLoading = true;
      })
      .addCase(deleteDailyBot.fulfilled, (state, action) => {
        state.deleteDailyBotLoading = false;
        state.dailyBots = state.dailyBots.filter(
          (bot) => bot.id !== action.meta.arg
        );
      })
      .addCase(deleteDailyBot.rejected, (state) => {
        state.deleteDailyBotLoading = false;
      });
  },
});

export default dailyBotsSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";
// import service from "auth/FetchInterceptor";
import axios from "axios";
import { SUPABASE_CLIENT_ANON_KEY } from "configs/AppConfig";

// const axios = require('axios');

export const initialState = {
  indexConfig: {},
  getIndexConfigLoading: false,
  ltp: {},
  getLtpLoading: false,
};

export const getIndexConfig = createAsyncThunk(
  "indexConfig",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/index_config`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data[0]) {
        console.log(response?.data[0]);
        return response?.data[0];
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getLtp = createAsyncThunk(
  "ltp",
  async (payload, { rejectWithValue }) => {
    try {
      console.log("LTP Payload", payload)
      const response = await axios.post(`${process.env.REACT_APP_SUPABASE_URL}functions/v1/market_data`, payload);
      if (response?.data?.data) {
        return response?.data?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const indexDataSlice = createSlice({
  name: "ltp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIndexConfig.pending, (state) => {
        state.getIndexConfigLoading = true;
      })
      .addCase(getIndexConfig.fulfilled, (state, action) => {
        state.getIndexConfigLoading = false;
        state.indexConfig = action.payload;
      })
      .addCase(getIndexConfig.rejected, (state) => {
        state.getIndexConfigLoading = false;
      })
      .addCase(getLtp.pending, (state) => {
        state.getLtpLoading = true;
      })
      .addCase(getLtp.fulfilled, (state, action) => {
        state.getLtpLoading = false;
        state.ltp = action.payload;
      })
      .addCase(getLtp.rejected, (state) => {
        state.getLtpLoading = false;
      });
  },
});

export default indexDataSlice.reducer;

import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import profile from "./slices/profileSlice";
import brokerAccounts from "./slices/brokerAccountsSlice";
import positions from "./slices/positionsSlice";
import positionsBook from "./slices/positionsBookSlice";
import ordersBook from "./slices/ordersBookSlice";
import portfolios from "./slices/portfoliosSlice";
import dailyBots from "./slices/dailyBotsSlice";
import tradingBots from "./slices/tradingBotsSlice";
import baseBots from "./slices/baseBotsSlice";
import indexData from "./slices/indexDataSlice";
import tvBots from "./slices/tvBotsSlice";
import analytics from "./slices/analyticsSlice";

const rootReducer = combineReducers({
  theme,
  auth,
  profile,
  brokerAccounts,
  positions,
  positionsBook,
  ordersBook,
  portfolios,
  dailyBots,
  tradingBots,
  baseBots,
  indexData,
  tvBots,
  analytics,
});

export default rootReducer;

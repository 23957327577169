import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";

export const initialState = {
  portfolios: [],
  getPortfoliosLoading: false,
  createPortfolioForBrokerLoading: false,
  updatePortfolioForBrokerLoading: false,
  deletePortfolioForBrokerLoading: false,
};

export const getPortfolios = createAsyncThunk(
  "Portfolios",
  async (brokerId, { rejectWithValue }) => {
    try {
      const response = await service.get(
        `/api/portfolios/all-portfolios/${brokerId}/`
      );
      if (response?.portfolios) {
        return response.portfolios;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createPortfolioForBroker = createAsyncThunk(
  "createPortfolioForBroker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/portfolios/portfolios/`,
        payload
      );
      if (response?.portfolios) {
        return response.portfolios;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updatePortfolioForBroker = createAsyncThunk(
  "updatePortfolioForBroker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.put(
        `/api/portfolios/portfolios/${payload?.id}/`,
        payload
      );
      if (response?.portfolios) {
        return response.portfolios;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deletePortfolioForBroker = createAsyncThunk(
  "deletePortfolioForBroker",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.delete(
        `/api/portfolios/portfolios/${payload?.id}/`
      );
      if (response?.portfolios) {
        return response.portfolios;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const portfoliosSlice = createSlice({
  name: "portfolios",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolios.pending, (state) => {
        state.getPortfoliosLoading = true;
      })
      .addCase(getPortfolios.fulfilled, (state, action) => {
        state.getPortfoliosLoading = false;
        state.portfolios = action.payload;
      })
      .addCase(getPortfolios.rejected, (state) => {
        state.getPortfoliosLoading = false;
      })
      .addCase(createPortfolioForBroker.pending, (state) => {
        state.createPortfolioForBrokerLoading = true;
      })
      .addCase(createPortfolioForBroker.fulfilled, (state, action) => {
        state.createPortfolioForBrokerLoading = false;
        state.portfolios = (state.portfolios || [])?.concat(action?.payload);
      })
      .addCase(createPortfolioForBroker.rejected, (state) => {
        state.createPortfolioForBrokerLoading = false;
      })
      .addCase(updatePortfolioForBroker.pending, (state) => {
        state.updatePortfolioForBrokerLoading = true;
      })
      .addCase(updatePortfolioForBroker.fulfilled, (state, action) => {
        state.updatePortfolioForBrokerLoading = false;
        const updatedIndex = state.portfolios.findIndex(
          (account) => account.id === action.payload.id
        );
        if (updatedIndex !== -1) {
          state.portfolios[updatedIndex] = action.payload;
        }
      })
      .addCase(updatePortfolioForBroker.rejected, (state) => {
        state.updatePortfolioForBrokerLoading = false;
      })
      .addCase(deletePortfolioForBroker.pending, (state) => {
        state.deletePortfolioForBrokerLoading = true;
      })
      .addCase(deletePortfolioForBroker.fulfilled, (state, action) => {
        state.deletePortfolioForBrokerLoading = false;
        state.portfolios?.filter((account) => account.id !== action.payload.id);
      })
      .addCase(deletePortfolioForBroker.rejected, (state) => {
        state.deletePortfolioForBrokerLoading = false;
      });
  },
});

export default portfoliosSlice.reducer;

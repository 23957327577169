import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";
// import service from "auth/FetchInterceptor";
import axios from "axios";
import { SUPABASE_CLIENT_ANON_KEY } from "configs/AppConfig";

export const initialState = {
  tvBots: [],
  getTvBotsLoading: false,
};

export const getTvBots = createAsyncThunk(
  "tvBots",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/tv_bots?select=*`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data) {
        console.log('TVBots', response?.data);
        return response?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const tvBotsSlice = createSlice({
  name: "tvBots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTvBots.pending, (state) => {
        state.getTvBotsLoading = true;
      })
      .addCase(getTvBots.fulfilled, (state, action) => {
        state.getTvBotsLoading = false;
        state.tvBots = action.payload;
      })
      .addCase(getTvBots.rejected, (state) => {
        state.getTvBotsLoading = false;
      });
  },
});

export default tvBotsSlice.reducer;

import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'login',
        path: `${APP_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'register',
        path: `${APP_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'error-page-1',
        path: `${APP_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'pages.setting',
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import('views/app-views/pages/setting')),
    },
    {
        key: 'position',
        path: `${APP_PREFIX_PATH}/position`,
        component: React.lazy(() => import('views/pages/Position')),
    },
    {
        key: 'strategy_alerts',
        path: `${APP_PREFIX_PATH}/strategy_alerts`,
        component: React.lazy(() => import('views/pages/StrategyAlerts')),
    },
    {
        key: 'broker.login',
        path: `${APP_PREFIX_PATH}/broker/:broker/*`,
        component: React.lazy(() => import('views/pages/BrokerLogin')),
    },
    {
        key: 'broker',
        path: `${APP_PREFIX_PATH}/broker`,
        component: React.lazy(() => import('views/pages/Broker')),
    },
    {
        key: 'portfolio',
        path: `${APP_PREFIX_PATH}/portfolio`,
        component: React.lazy(() => import('views/pages/Portfolio')),
    },
    {
        key: 'position_book',
        path: `${APP_PREFIX_PATH}/position_book`,
        component: React.lazy(() => import('views/pages/PositionBook')),
    },
    {
        key: 'order_book',
        path: `${APP_PREFIX_PATH}/order_book`,
        component: React.lazy(() => import('views/pages/OrderBook')),
    },
    {
        key: 'trading_view_chart',
        path: `${APP_PREFIX_PATH}/trading_view_chart`,
        component: React.lazy(() => import('views/pages/TradingViewChart')),
    },
    {
        key: 'tv_chart',
        path: `${APP_PREFIX_PATH}/tv_chart`,
        component: React.lazy(() => import('views/pages/TVChart')),
    },
    {
        key: 'screener-ticker',
        path: `${APP_PREFIX_PATH}/screener_ticker`,
        component: React.lazy(() => import('views/pages/ScreenerTicker')),
    },
    {
        key: 'strategies',
        path: `${APP_PREFIX_PATH}/strategies`,
        component: React.lazy(() => import('views/app-views/strategies')),
    },
    {
        key: 'recommendations',
        path: `${APP_PREFIX_PATH}/recommendations`,
        component: React.lazy(() => import('views/app-views/recommendations')),
    },
    {
        key: 'market-view',
        path: `${APP_PREFIX_PATH}/market_view`,
        component: React.lazy(() => import('views/app-views/marketView')),
    },
    {
        key: 'heat-map',
        path: `${APP_PREFIX_PATH}/heat_map`,
        component: React.lazy(() => import('views/app-views/heatMap')),
    },
    {
        key: 'live-trade',
        path: `${APP_PREFIX_PATH}/dashboards/live-trade`,
        component: React.lazy(() => import('views/pages/LiveTrade')),
    },
    {
        key: 'strategy-builder',
        path: `${APP_PREFIX_PATH}/strategy-builder`,
        component: React.lazy(() => import('views/pages/StrategyBuilder')),
    },
]
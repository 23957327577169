import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";

export const initialState = {
  positionsBook: [],
  getPositionsBookLoading: false,
  placeTradesLoading: false,
  squareOffPositionLoading: false,
  newPositionLoading: false,
};

export const getPositionsBook = createAsyncThunk(
  "positionsBook/getPositionsBook",
  async (payload, { rejectWithValue }) => {
    try {
      const startDate = payload?.date ? '?start_date=' + payload?.date : ''
      // const response = await service.get(`/api/position-book/position-book/${payload}`);
      const response = await service.get(`/api/position-book/position-book/${payload?.accountNumber}${startDate}`);
      if (response?.position_book) {
        return response.position_book;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const newPosition = createAsyncThunk(
  "newPosition",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/order-book/order-book/execute_order/`,
        payload
      );
      if (response) {
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const placeTrades = createAsyncThunk(
  "placeTrade",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/order-book/order-book/place_trades/`,
        payload
      );
      if (response) {
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const squareOffPosition = createAsyncThunk(
  "squareOffPosition",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/order-book/order-book/execute_order/`,
        payload
      );
      if (response) {
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const positionsBookSlice = createSlice({
  name: "positionsBook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPositionsBook.pending, (state) => {
        state.getPositionsBookLoading = true;
      })
      .addCase(getPositionsBook.fulfilled, (state, action) => {
        state.getPositionsBookLoading = false;
        state.positionsBook = action.payload;
      })
      .addCase(getPositionsBook.rejected, (state) => {
        state.getPositionsBookLoading = false;
      })
      .addCase(newPosition.pending, (state) => {
        state.newPositionLoading = true;
      })
      .addCase(newPosition.fulfilled, (state, action) => {
        state.newPositionLoading = false;
      })
      .addCase(newPosition.rejected, (state) => {
        state.newPositionLoading = false;
      })
      .addCase(squareOffPosition.pending, (state) => {
        state.squareOffPositionLoading = true;
      })
      .addCase(squareOffPosition.fulfilled, (state, action) => {
        state.squareOffPositionLoading = false;
      })
      .addCase(squareOffPosition.rejected, (state) => {
        state.squareOffPositionLoading = false;
      })
      .addCase(placeTrades.pending, (state) => {
        state.placeTradesLoading = true;
      })
      .addCase(placeTrades.fulfilled, (state, action) => {
        state.placeTradesLoading = false;
      })
      .addCase(placeTrades.rejected, (state) => {
        state.placeTradesLoading = false;
      });
  },
});

export default positionsBookSlice.reducer;

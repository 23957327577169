import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";
import axios from "axios";
import { SUPABASE_CLIENT_ANON_KEY } from "configs/AppConfig";

export const initialState = {
  tvCommentary: [],
  getTvCommentaryLoading: false,
  tvData: [],
  getTvDataLoading: false,
  tvDailyData: [],
  getTvDailyDataLoading: false,
  tvScreener: [],
  getTvScreenerLoading: false,
  recommendations: [],
  getRecommendationsLoading: false,
};

export const getTvCommentary = createAsyncThunk(
  "tvCommentary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/tv_commentary?select=*&order=created_at.desc&limit=100`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data) {
        console.log('TV_Com', response?.data);
        return response?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getTvData = createAsyncThunk(
  "tvData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/tv_data?select=*&order=created_at.desc&limit=20&indicator=is.null`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data) {
        console.log('TV_Data', response?.data);
        return response?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getTvDailyData = createAsyncThunk(
  "tvDailyData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/tv_daily_data?select=*&order=created_at.desc&limit=10`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data) {
        console.log('TV_Daily_Data', response?.data);
        return response?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getTvScreener = createAsyncThunk(
  "tvScreener",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/tv_data?select=*&order=created_at.desc&limit=10&indicator=eq.screener`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data) {
        console.log('TV_Screener', response?.data);
        return response?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getRecommendations = createAsyncThunk(
  "recommendations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SUPABASE_URL}rest/v1/screener?select=*&order=display_order`, {
        headers: {
          'apikey': SUPABASE_CLIENT_ANON_KEY,
          'Authorization': `Bearer ${SUPABASE_CLIENT_ANON_KEY}`
        }
      });
      if (response?.data) {
        console.log('recommendations', response?.data);
        return response?.data;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTvCommentary.pending, (state) => {
        state.getTvCommentaryLoading = true;
      })
      .addCase(getTvCommentary.fulfilled, (state, action) => {
        state.getTvCommentaryLoading = false;
        state.tvCommentary = action.payload;
      })
      .addCase(getTvCommentary.rejected, (state) => {
        state.getTvCommentaryLoading = false;
      })
      .addCase(getTvData.pending, (state) => {
        state.getTvDataLoading = true;
      })
      .addCase(getTvData.fulfilled, (state, action) => {
        state.getTvDataLoading = false;
        state.tvData = action.payload;
      })
      .addCase(getTvData.rejected, (state) => {
        state.getTvDataLoading = false;
      })
      .addCase(getTvDailyData.pending, (state) => {
        state.getTvDailyDataLoading = true;
      })
      .addCase(getTvDailyData.fulfilled, (state, action) => {
        state.getTvDailyDataLoading = false;
        state.tvDailyData = action.payload;
      })
      .addCase(getTvDailyData.rejected, (state) => {
        state.getTvDailyDataLoading = false;
      })
      .addCase(getTvScreener.pending, (state) => {
        state.getTvScreenerLoading = true;
      })
      .addCase(getTvScreener.fulfilled, (state, action) => {
        state.getTvScreenerLoading = false;
        state.tvScreener = action.payload;
      })
      .addCase(getTvScreener.rejected, (state) => {
        state.getTvScreenerLoading = false;
      })
      .addCase(getRecommendations.pending, (state) => {
        state.getRecommendationsLoading = true;
      })
      .addCase(getRecommendations.fulfilled, (state, action) => {
        state.getRecommendationsLoading = false;
        state.recommendations = action.payload;
      })
      .addCase(getRecommendations.rejected, (state) => {
        state.getRecommendationsLoading = false;
      });
  },
});

export default analyticsSlice.reducer;

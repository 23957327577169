import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "auth/FetchInterceptor";

export const initialState = {
  ordersBook: [],
  getOrdersBookLoading: false,
  modifyOrderLoading: false,
  cancelOrderLoading: false,
};

export const getOrdersBook = createAsyncThunk(
  "ordersBook/getOrdersBook",
  async (payload, { rejectWithValue }) => {
    try {
      const startDate = payload?.date ? '?start_date=' + payload?.date : ''
      // const response = await service.get(`/api/order-book/order-book/${payload}`);
      const response = await service.get(`/api/order-book/order-book/${payload?.accountNumber}${startDate}`);
      if (response?.order_book) {
        return response.order_book;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const modifyOrder = createAsyncThunk(
  "modifyOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/order-book/order-book/modify_order/`,
        payload
      );
      if (response) {
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

// Modify success message
// {
//   "code": "4F2B7C7854859U",
//   "status": "success",
//   "message": "No message from broker"
// }
// Modify error message
// {
//   "message": "Failed to make request to /orders/regular/24020220016872244. Response: Couldn't find that `order_id`."
// }
// {
//   "message": "Failed to make request to /orders/regular/240202200168722. Response: Invalid `price`."
// }
// {
//   "message": "Failed to make request to /orders/regular/240202200168722. Response: The instrument you are placing an order for has either expired or does not exist."
// }4
// {
//   "message": "Failed to make request to /orders/regular/240202200168722. Response: The instrument you are placing an order for has either expired or does not exist."
// }

export const cancelOrder = createAsyncThunk(
  "cancelOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await service.post(
        `/api/order-book/order-book/cancel_order/`,
        payload
      );
      if (response) {
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

// cancel success message
// {
//   "status": "success",
//   "cancellation_status": {
//       "status": "success",
//       "data": {
//           "order_id": "240202200173030"
//       }
//   }
// }

// cancel error message
// {
//   "message": "Failed to make request to /orders/regular/240202200173030. Response: Order cannot be cancelled as it is being processed. Try later."
// }

export const ordersBookSlice = createSlice({
  name: "ordersBook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersBook.pending, (state) => {
        state.getOrdersBookLoading = true;
      })
      .addCase(getOrdersBook.fulfilled, (state, action) => {
        state.getOrdersBookLoading = false;
        state.ordersBook = action.payload;
      })
      .addCase(getOrdersBook.rejected, (state) => {
        state.getOrdersBookLoading = false;
      })
      .addCase(modifyOrder.pending, (state) => {
        state.modifyOrderLoading = true;
      })
      .addCase(modifyOrder.fulfilled, (state, action) => {
        state.modifyOrderLoading = false;
      })
      .addCase(modifyOrder.rejected, (state) => {
        state.modifyOrderLoading = false;
      })
      .addCase(cancelOrder.pending, (state) => {
        state.cancelOrderLoading = true;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.cancelOrderLoading = false;
      })
      .addCase(cancelOrder.rejected, (state) => {
        state.cancelOrderLoading = false;
      });
  },
});

export default ordersBookSlice.reducer;
